define("discourse/plugins/discourse-group-global-notice/initializers/discourse-group-global-notice", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeDiscourseGroupGlobalNotice(api) {
    const currentUser = api.getCurrentUser();
    if (currentUser) {
      currentUser.global_notices.forEach(notice => {
        api.addGlobalNotice(notice.text, notice.id, {
          dismissable: notice.dismissable,
          level: notice.level,
          persistentDismiss: notice.persistent_dismiss,
          visibility: notice.visibility,
          dismissDuration: notice.dismiss_duration ? moment.duration(notice.dismiss_duration, "seconds") : undefined
        });
      });
    }
  }
  var _default = _exports.default = {
    name: "discourse-group-global-notice",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeDiscourseGroupGlobalNotice);
    }
  };
});